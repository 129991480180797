import { config } from "../config";

const API_URL = config.java_api_url;

export const getEvents = (accessToken) => {
  return fetch(`${API_URL}/events`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getEvent = (accessToken, body) => {
  return fetch(`${API_URL}/events/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const updateTimeEvent = (accessToken, body) => {
  return fetch(`${API_URL}/events/${body.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body.bodyRequest),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};
//---- Типы обновления для updateTimeEvent----------------------------------------------------------------------------//
// start_registration
// end_registration
// start_voting
// end_voting
// prolong
// prolong_vote
// prolong_reg
//--------------------------------------------------------------------------------------------------------------------//
export const getStatistics = (accessToken, body) => {
  return fetch(`${API_URL}/events/statistics/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error();
    });
};

export const getVoters = (accessToken, body) => {
  return fetch(`${API_URL}/events/voters/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const updateStatistics = (accessToken, body) => {
  return fetch(`${API_URL}/events/statistics/${body.id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getObservers = (accessToken, body) => {
  return fetch(`${API_URL}/events/observers/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getCounters = (accessToken, body) => {
  return fetch(`${API_URL}/events/counters/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getUnpauseEvent = (accessToken, id) => {
  return fetch(`${API_URL}/events/unpause/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const deleteEvent = (accessToken, body) => {
  return fetch(`${API_URL}/events/deleteForce`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getStartRegenerateProtocol = (accessToken, id) => {
  return fetch(`${API_URL}/events/calculate/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getSendNotify = (accessToken, id) => {
  return fetch(`${API_URL}/events/notify/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const postSignProtocol = (accessToken, body) => {
  return fetch(`${API_URL}/events/sign/${body.id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getNetworkStatistics = (accessToken, body) => {
  return fetch(`${API_URL}/events/network-statistics/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        const result = res.json();
        return result;
      }
    })

    .catch((err) => {
      throw new Error();
    });
};

export const getNetworkStatistics_2 = (accessToken, body) => {
  return fetch(`${API_URL}/events/network-statistics/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        const result = res.json();
        return result;
      }
    })

    .catch((err) => {
      throw new Error();
    });
};

export const changeProtocol = (accessToken, body) => {
  return fetch(`${API_URL}/events/config/${body.eventId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getQRLogins = (accessToken, { id, lifeTime }) => {
  return fetch(`${API_URL}/events/tokens/${id}${lifeTime}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};
