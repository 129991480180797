import {useEffect, useState} from "react";

function useSetRuleQuestionGT(startValueInput, endValueInput, activeRuleSelect, rows) {

    const [valueRuleGT, setValueRuleGT] = useState(0);

    useEffect(() => {
        let isMount = true;

        if (activeRuleSelect === true && startValueInput < endValueInput && startValueInput !== 1 && endValueInput !== rows.length) {
            isMount && setValueRuleGT(startValueInput)
        }else if(activeRuleSelect === true && startValueInput === 1 && endValueInput === rows.length) {
            isMount && setValueRuleGT(startValueInput);
        } else {
            isMount && setValueRuleGT(-1)
        }

        return () => {
            isMount = false;
        }
    },[startValueInput, endValueInput, activeRuleSelect, rows])

    return {valueRuleGT};
}

export default useSetRuleQuestionGT;