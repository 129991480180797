import {useEffect, useState} from "react";

function useSetRuleQuestionLT(startValueInput, endValueInput, activeRuleSelect, rows) {

    const [valueRulesLT, setValueRulesLT] = useState(0);

    useEffect(() => {
        let isMount = true;

        if (activeRuleSelect === true && startValueInput < endValueInput && startValueInput !== 1 && endValueInput !== rows.length) {
            isMount && setValueRulesLT(endValueInput);
        } else if(activeRuleSelect === true && startValueInput === 1 && endValueInput === rows.length) {
            isMount && setValueRulesLT(endValueInput);
        } else {
            isMount && setValueRulesLT(-1);
        }

        return () => {
            isMount = false;
        }
    },[startValueInput, endValueInput, activeRuleSelect, rows])

    return {valueRulesLT};
}

export default useSetRuleQuestionLT;