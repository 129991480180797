import { config } from "../config";

const API_URL = config.java_api_url;
const API_URL_AUTH = config.auth_api_url;

export const findUsers = (accessToken, body) => {
  return fetch(`${API_URL_AUTH}/users/get-list?idType=EMAIL`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const findUsersByNames = (accessToken, body) => {
  return fetch(`${API_URL_AUTH}/users/get-list?idType=NAME`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getUsers = (accessToken) => {
  return fetch(`${API_URL}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getUser = (accessToken, userId) => {
  return fetch(`${API_URL}/users/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const updateUser = (accessToken, body) => {
  return fetch(`${API_URL}/users/${body.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body.bodyRequest),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const updatePasswordUser = (accessToken, body) => {
  return fetch(`${API_URL}/users/password/${accessToken}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const addNewUser = async (
  accessToken,
  {
    registration_type = "EMAIL",
    first_name = "Пользователь",
    last_name = "Новый",
    second_name = "",
    email,
  }
) => {
  return await fetch(`${API_URL_AUTH}/users/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      registration_type: registration_type,
      first_name: first_name,
      last_name: last_name,
      second_name: second_name,
      email: email,
      realm: config.realm,
    }),
  })
    .then((res) => {
      return res.ok ? res.json() : res;
    })

    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};

export const patchUser = async (accessToken, body) => {
  return await fetch(`${API_URL_AUTH}/users/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      return res.ok ? res.json() : res;
    })

    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};
